import SimpleFlowModel from '@/models/SimpleFlowModel'
import CurrencyFilter from '@/utils/filters/currency'
import { roundNumber } from '@/utils/mathUtils'
import $constants from '@/services/constants'
import $i18n from '@/i18n'
import $store from '@/store'
import _ from 'lodash'

export default class Generator extends SimpleFlowModel {

    defaults() {
        return {
            ...super.defaults(),
            amount     : null,
            spent_value: null,
            reversal_id: null,
            origin     : null,
        }
    }

    // Não precisa de validação, pois não havera o cadastro nesta app

    // Getters

    /**
     * Retorna a string identificadora de um ícone que representa a geração de acordo com sua origem
     *
     * @returns {string}
     */
    get icon() {
        if (this.origin == $constants.getConstant('PROMOTION_GENERATOR_ORIGIN'))
            return '$cp_award'
        
        return '$cp_credit_card'
    }

    /**
     * Retorna um título completo que indica quantidade e valor para a versão desktop
     *
     * @returns {string}
     */
    get title() {
        let displayMode = $store.getters['company/display_mode'] || 'POINTS'
        let hidePoints = $store.getters['company/hide_points']
        let hideCashback = $store.getters['company/hide_cashback']

        if (this.amount == 0) {
            if (displayMode == $constants.getConstant('DISPLAY_TYPE_PERCENTAGE') || displayMode == $constants.getConstant('DISPLAY_TYPE_CASHBACK'))
                return $i18n.t('models.Generator.title_percentage_cash_zero')

            return $i18n.t('models.Generator.title_points_zero')
        }

        let origin = this.origin || 'default'

        if (hidePoints) {
            return $i18n.t(`models.Generator.title_hide_points.${origin}_origin`, {
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (hideCashback) {
            return $i18n.t(`models.Generator.title_hide_cashback.${origin}_origin`, {
                points_amount: this.points_amount,
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (displayMode == $constants.getConstant('DISPLAY_TYPE_PERCENTAGE'))
            return $i18n.t(`models.Generator.title_percentage.${origin}_origin`, {
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
                points_percentage: this.points_percentage,
            })

        if (displayMode == $constants.getConstant('DISPLAY_TYPE_CASHBACK'))
            return $i18n.t(`models.Generator.title_cash.${origin}_origin`, {
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
                points_value: CurrencyFilter(this.points_value),
            })

        // Points
        return $i18n.t(`models.Generator.title_points.${origin}_origin`, {
            points_amount: this.points_amount,
            value: CurrencyFilter(this.spent_value),
            points_value: CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })
    }

    /**
     * Retorna um título completo que indica quantidade e valor para a versão mobile
     *
     * @returns {string}
     */
    get titleMobile() {
        let displayMode = $store.getters['company/display_mode']
        let hidePoints = $store.getters['company/hide_points']
        let hideCashback = $store.getters['company/hide_cashback']

        if (this.value == 0) {
            if (displayMode == $constants.getConstant('DISPLAY_TYPE_PERCENTAGE') || displayMode == $constants.getConstant('DISPLAY_TYPE_CASHBACK'))
                return $i18n.t('models.Generator.title_percentage_cash_zero')

            return $i18n.t('models.Generator.title_points_zero')
        }

        if (hidePoints) {
            return $i18n.t('models.Generator.title_mobile_hide_points')
        }

        if (hideCashback) {
            return $i18n.t('models.Generator.title_mobile_hide_cashback', {
                points_amount: this.points_amount,
            })
        }

        if (displayMode == $constants.getConstant('DISPLAY_TYPE_PERCENTAGE'))
            return $i18n.t('models.Generator.title_mobile_percentage', {
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
                points_percentage: this.points_percentage,
            })

        if (displayMode == $constants.getConstant('DISPLAY_TYPE_CASHBACK'))
            return $i18n.t('models.Generator.title_mobile_cash', {
                value: CurrencyFilter(this.spent_value),
                subsidiary_name: this.subsidiary_name,
                points_value: CurrencyFilter(this.points_value),
            })

        // Points
        return $i18n.t('models.Generator.title_mobile_points', {
            points_amount  : this.points_amount,
            value          : CurrencyFilter(this.spent_value),
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })        
    }
    
    /**
     * Retorna a ação realizada pelo modelo
     *
     * @returns {string}
     */
    get action() {
        return $i18n.t('models.Generator.action')
    }

    /**
     * Retorna um subtítulo indicando se houve cancelamento
     *
     * @returns {string}
     */
    get subtitle() {
        if (!this.reversal_id) {
            return ''
        }

        let hidePoints = $store.getters['company/hide_points']
        if (hidePoints) {
            return $i18n.t('models.Generator.subtitle.reversal_hide_points')
        }

        return $i18n.t('models.Generator.subtitle.reversal', {
            points_amount  : this.points_amount,
            value          : CurrencyFilter(this.spent_value),
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })
    }

    /**
     * @override
     *
     * Retorna a quantidade total de pontos gerados
     *
     * @returns {string}
     */
    get points_amount() {
        return this.amount
    }

    /**
     * @override
     *
     * Retorna o valor total dos pontos gerados
     *
     * @returns {Number}
     */
    get points_value() {
        return this.value
    }
    
    /**
     * Retorna o porcentagem de cashback recebido
     *
     * @returns {Number}
     */
    get points_percentage() {
        return Number(roundNumber((this.value / this.spent_value) * 100))
    }

    /**
     * Retorna a data que os pontos irão expirar
     *
     * @returns {Number}
     */
    get expires_at() {
        return this.points && this.points[0] ? this.points[0].expires_at : null
    }

    /**
     * Retorna um texto contendo a quantidade de pontos/cashback gerado nesta geração
     * de acordo com a config de display.
     *
     * @argument {object}  config                   configurações
     * @argument {boolean} config.preventPercentage se o display é percentage, faz o fallback para cashback (útil em casos que não existe com o que relacionar a porcentagem, ex: ganhar pontos "de graça")
     * @argument {string}  config.forceDisplayMode  substituir o modo de display da store por este valor
     * @returns {string}
     */
    getDynamicValueLabel(config = {}) {
        const { preventPercentage, forceDisplayMode } = _.defaultTo(config, {
            preventPercentage: false,
            forceDisplayMode : undefined,
        })

        let displayMode = forceDisplayMode || $store.getters['company/display_mode']

        if (preventPercentage && displayMode == $constants.getConstant('DISPLAY_TYPE_PERCENTAGE'))
            displayMode = $constants.getConstant('DISPLAY_TYPE_CASHBACK')

        const mapping = {
            [$constants.getConstant('DISPLAY_TYPE_POINTS')]    : $i18n.tc('globals.amount_points_short', this.amount),
            [$constants.getConstant('DISPLAY_TYPE_CASHBACK')]  : CurrencyFilter(this.value),
            [$constants.getConstant('DISPLAY_TYPE_PERCENTAGE')]: $i18n.t('globals.percentage', this.points_percentage),
        }

        return mapping[displayMode]
    }
}